//账号列表
export const accountCols = [
  {
    prop: 'companyName',
    label: '收款企业名称',
    minWidth: '200'
  },
  {
    prop: 'bankName',
    label: '开户银行',
    minWidth: '200'
  },
  {
    label: '银行分类',
    minWidth: '200',
    slotName: 'bankSlot'
  },
  {
    prop: 'bankAccountNo',
    label: '银行卡号',
    minWidth: '200'
  },
  {
    label: '更新时间',
    minWidth: '200',
    slotName: 'updateTimeSlot'
  }
]
